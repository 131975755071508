/* Dashboard.css */

.dashboard-container {
  padding: 30px;
  background: white;
}

.dashboard-title {
  margin-bottom: 24px;
  font-size: 24px;
}

.dashboard-card {
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dashboard-card-title {
  display: flex;
  align-items: center;
}

.dashboard-card-title-text {
  font-weight: bold;
  margin-left: 8px;
}

.dashboard-card .ant-card-body {
  padding: 20px;
}

body[data-theme='dark'] {
  .dashboard-container {
    background: #333;
    color: white;
  }

  .dashboard-card {
    background-color: #444;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .dashboard-card-title-text {
    color: #fff;
  }

  .dashboard-card .ant-card-body {
    color: #fff;
  }

  .dashboard-title {
    color: white;
  }

  .support-requests-card .ant-list-item-meta {
    color: #fff; /* Text color for list items */
  }

  .support-requests-card .ant-list-item-meta .ant-list-item-meta-title {
    color: #fff; /* Title color for list items */
  }

  .support-requests-card .ant-list-item-meta .ant-list-item-meta-description {
    color: #fff; /* Description color for list items */
  }

  .recent-activities-card2 {
    color: white;
  }

  .top-courses-card2 {
    color: white;
  }

  .top-courses-card2 .ant-table {
    color: #fff; /* Text color for table */
    background-color: #444; /* Background color for table */
  }

  .top-courses-card2 .ant-table-thead > tr > th {
    color: #fff; /* Header text color for table */
    background-color: #555; /* Header background color for table */
  }

  .top-courses-card2 .ant-table-tbody > tr > td {
    color: #fff; /* Body text color for table */
    background-color: #444; /* Row background color for table */
  }

  .top-courses-card2 .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #555; /* Row hover background color for table */
  }

  .top-courses-card2 .ant-tag {
    color: #fff; /* Text color for tags */
    background-color: #555; /* Background color for tags */
  }

  .top-courses-card2 .ant-badge-status-processing {
    background-color: #fff; /* Background color for badges */
  }

  .top-courses-card2 .ant-typography-success {
    color: #fff; /* Text color for success type */
  }

  .text {
    color: white;
  }
}

/* Mobile Styles */

@media (max-width: 768px) {
  .dashboard-container {
    padding: 15px;
  }

  .dashboard-title {
    font-size: 20px;
  }

  .dashboard-card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .dashboard-card .ant-card-body {
    padding: 15px;
  }

  .dashboard-card-title-text {
    font-size: 16px;
  }

  .top-courses-card2 .ant-table {
    font-size: 14px;
  }

  .top-courses-card2 .ant-table-thead > tr > th {
    font-size: 14px;
  }

  .top-courses-card2 .ant-table-tbody > tr > td {
    font-size: 14px;
  }

  .recent-activities-card2,
  .top-courses-card2 {
    font-size: 14px;
  }

  .text {
    font-size: 14px;
  }
}