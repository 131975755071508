body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  justify-content: center;
  align-items: center;
}

.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 5px;
}

.inner-container {
  display: flex;
  max-width: 700px;
  width: 100%;
  background-color: #fff;
  border-radius: 6px; 
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.image-card {
  flex: 1;
  background-image: url('https://s3.ap-south-1.amazonaws.com/ecom-assets.samyutha.com/WhatsApp%20Image%202024-08-06%20at%203.46.06%20PM.jpeg');
  background-size: cover;
  background-position: center;
  min-height: 200px; /* Further reduced */
}

.card {
  flex: 1;
  padding: 20px; /* Further reduced */
}

.title {
  color: #333;
  margin-bottom: 15px !important; /* Further reduced */
  text-align: center;
  font-size: 20px !important; /* Further reduced */
}

.form {
  max-width: 240px; /* Further reduced */
  margin: 0 auto;
}

.input {
  margin-bottom: 10px; /* Further reduced */
}

.button {
  background-color: #6e45e2;
  border-color: #6e45e2;
}

.button:hover {
  background-color: #5a36c9;
  border-color: #5a36c9;
}

/* Responsive design */
@media (max-width: 768px) {
  .inner-container {
    flex-direction: column;
    max-width: 320px; /* Further reduced for mobile */
  }

  .image-card {
    display: block;
    width: 100%;
    height: 120px; /* Further reduced */
  }

  .card {
    padding: 15px; /* Further reduced */
  }
}

@media (max-width: 480px) {
  .outer-container {
    padding: 3px; /* Further reduced */
  }

  .card {
    padding: 12px; /* Further reduced */
  }

  .title {
    font-size: 18px !important; /* Further reduced */
  }

  .form {
    max-width: 100%;
  }
}

/* Additional responsive adjustments */
@media (min-width: 769px) and (max-width: 1024px) {
  .inner-container {
    max-width: 80%; /* Further reduced */
  }
}

/* Ensure form elements are responsive */
.ant-form-item {
  margin-bottom: 12px; /* Further reduced */
}

.ant-input-affix-wrapper {
  max-width: 100%;
}

.ant-btn {
  height: auto;
  padding: 6px 10px; /* Further reduced */
}

/* Accessibility improvements */
.ant-input:focus,
.ant-input-password:focus {
  box-shadow: 0 0 0 2px rgba(110, 69, 226, 0.2);
}

/* Smooth transitions */
.ant-btn,
.ant-input,
.ant-input-password {
  transition: all 0.3s ease;
}
