.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;

  padding: 20px;
}
.search-input {
  flex-grow: 1; 
  margin-right: 16px; 
  width: 300px;
  border-radius: 4px;
  border-color: #d9d9d9;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #1890ff; 
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.add-button {
  background-color: #52c41a; /* Green */
  border-color: #52c41a;
  color: #fff;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.add-button:hover {
  background-color: #43a020;
  border-color: #43a020;
}

.edit-button {
  background-color: #1890ff; /* Blue */
  border-color: #1890ff;
  color: #fff;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.edit-button:hover {
  background-color: #0d6efd; /* Darker blue */
  border-color: #0d6efd;
}

.delete-button {
  background-color: #ff4d4f; /* Red */
  border-color: #ff4d4f;
  color: #fff;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.delete-button:hover {
  background-color: #e03a3e; /* Darker red */
  border-color: #e03a3e;
}

.modal-footer-button {
  background-color: #1890ff; /* Blue */
  border-color: #1890ff;
  color: #fff;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
}

.modal-footer-button:hover {
  background-color: #0d6efd; /* Darker blue */
  border-color: #0d6efd;
}

.table-container {
  margin-top: 0px;
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}


.ant-table-tbody > tr > td {
  padding-left: 16px; /* Add padding to left of each table cell */
}

.ant-table-thead > tr > th {
  padding-left: 16px; /* Add padding to left of each header cell */
}

.modal-custom {
  top: 20px;
}


@media (max-width: 768px) {
  .page-title {
    font-size: 18px;
    margin-bottom: 12px;
    flex-direction: column;
  }

  .search-input {
    width: 100%;
    margin-bottom: 12px;
  }

  .add-button,
  .edit-button,
  .delete-button {
    width: 100%;
    margin-bottom: 8px;
  }

  .table-container {
    margin-top: 8px;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding-left: 8px;
  }

  .modal-custom {
    width: 90%;
    max-width: 500px;
  }

  .modal-footer-button {
    width: 100%;
  }

  .table-container {
    overflow: scroll;
  }
}


@media (max-width: 480px) {
  .page-title {
    font-size: 16px;
    margin-bottom: 8px;
    flex-direction: column;
  }

  .search-input {
    width: 100%;
    margin-bottom: 8px;
  }

  .add-button,
  .edit-button,
  .delete-button {
    width: 100%;
    margin-bottom: 6px;
    font-size: 14px;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding-left: 4px;
  }

  .modal-custom {
    width: 95%;
    max-width: 400px;
  }

  .modal-footer-button {
    width: 100%;
    font-size: 14px;
  }
  .table-container {
    overflow: scroll;
  }
} 
body[data-theme='dark'] {
  .tittle-name {
    color: white;
  }
  .page-title {
    color: #fff;
  }

  .search-input {
    background-color: #444;
    border-color: #666;
    color: #fff;
  }

  .search-input:focus {
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    background-color: #333 !important;
    border-color: #666;
    color: #fff;
  }

  .modal-custom {
    background-color: #333;
    color: #fff;
  }

  .modal-footer-button {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #fff;
  }

  .modal-footer-button:hover {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }

  .add-button,
  .edit-button,
  .delete-button {
    color: #fff;
  }

  .table-container {
    background-color: black;
  }
}